import React from 'react'

import { ButtonVariant } from '@amzn/stencil-react-components/button'
import { Input, InputWrapper } from '@amzn/stencil-react-components/form'
import { IconBin } from '@amzn/stencil-react-components/icons'
import { Row, Spacer, View } from '@amzn/stencil-react-components/layout'

import { Button } from 'src/components/Button'
import { ResponsiveRow } from 'src/components/ResponsiveRow'
import { ItemPoolDistributionDTO } from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import {
    ItemPoolModal,
    ItemPoolModalButtonProps,
} from 'src/pages/module-builder/SelfServeManualItemPoolBuilder/ItemPoolModal'

export interface AdaptiveEngineItemPoolDistributionEditorProps {
    itemPoolDistribution: ItemPoolDistributionDTO
    setItemPoolVersionId?: (value: string) => void
    setMinItems?: (value: number) => void
    setPercentage?: (value: number) => void
    removeItemPoolDistribution?: () => void
    isScored: boolean
    idPrefix?: string
}

export function AdaptiveEngineItemPoolDistributionEditor({
    itemPoolDistribution: { itemPoolId: itemPoolVersionId, minItems, percentage },
    setMinItems,
    setItemPoolVersionId,
    setPercentage,
    removeItemPoolDistribution,
    isScored,
    idPrefix = 'item-pool-distribution',
}: AdaptiveEngineItemPoolDistributionEditorProps) {
    return (
        <ResponsiveRow gridGap='S300' alignItems='flex-end'>
            <Row flex='3' alignItems='flex-end' gridGap='S200'>
                <View flex='1'>
                    <InputWrapper
                        id={`${idPrefix}-item-pool-version-id`}
                        dataTestId={`${idPrefix}-item-pool-version-id`}
                        labelText={`${isScored ? 'Scored' : 'Unscored'} Item Pool Version ID`}
                    >
                        {(inputProps) => (
                            <Input
                                type='text'
                                {...inputProps}
                                value={itemPoolVersionId}
                                onChange={(e) => setItemPoolVersionId?.(e.target.value)}
                            />
                        )}
                    </InputWrapper>
                </View>
                <View flex='0'>
                    <ItemPoolModal
                        itemPoolVersionId={itemPoolVersionId}
                        existingPool={false}
                        setSelectItemPoolVersionId={setItemPoolVersionId}
                    >
                        {({ onClick, disabled }: ItemPoolModalButtonProps) => (
                            <Button
                                dataTestId={`${idPrefix}-select-item-pool`}
                                style={{ width: '100%' }}
                                variant={ButtonVariant.Secondary}
                                disabled={disabled}
                                aria-disabled={disabled}
                                onClick={onClick}
                            >
                                Select item pool
                            </Button>
                        )}
                    </ItemPoolModal>
                </View>
            </Row>
            {!isScored && <Spacer /* 3 = 50% width */ flex='3 0 0' />}
            {isScored && (
                <>
                    <View flex='1 1 0'>
                        <InputWrapper
                            id={`${idPrefix}-min-items`}
                            dataTestId={`${idPrefix}-min-items`}
                            labelText='Minimum items'
                        >
                            {(inputProps) => (
                                <Input
                                    type='number'
                                    {...inputProps}
                                    min={0}
                                    step={1}
                                    value={minItems}
                                    onChange={(e) => {
                                        setMinItems?.(parseInt(e.target.value) || 0)
                                    }}
                                />
                            )}
                        </InputWrapper>
                    </View>
                    <View flex='1 1 0'>
                        <InputWrapper
                            id={`${idPrefix}-pct`}
                            dataTestId={`${idPrefix}-pct`}
                            labelText='Percentage'
                        >
                            {(inputProps) => (
                                <Input
                                    flex='1'
                                    type='number'
                                    {...inputProps}
                                    min={0}
                                    max={100}
                                    value={percentage}
                                    onChange={(e) => setPercentage?.(parseInt(e.target.value) || 0)}
                                />
                            )}
                        </InputWrapper>
                    </View>
                    <View flex='0 0 0'>
                        <Button
                            isDestructive={true}
                            variant={ButtonVariant.Secondary}
                            onClick={removeItemPoolDistribution}
                            aria-label='Remove this item pool'
                            icon={<IconBin aria-hidden />}
                        />
                    </View>
                </>
            )}
        </ResponsiveRow>
    )
}
