import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'

import {
    InputWrapper,
    LabelPosition,
    Radio,
    RecommendedLengthFooter,
    Select,
    TextArea,
} from '@amzn/stencil-react-components/form'
import { TokenType, useRecommendedLength } from '@amzn/stencil-react-components/hooks'
import { Col, Hr, Row, Spacer, View } from '@amzn/stencil-react-components/layout'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only'
import { Spinner } from '@amzn/stencil-react-components/spinner'
import { H2, H3, H4, Label, Text } from '@amzn/stencil-react-components/text'

import { Markdown } from 'src/components/Markdown'
import { getReviewersList } from 'src/config.app'
import { ModuleReviewContext } from 'src/contexts/ModuleReviewContext'
import { CommentDTO } from 'src/models/dto/approval/CommentDTO'
import {
    CreateModuleReviewInput,
    ModuleGroupReviewRequest,
    ReviewDTO,
    ReviewerMember,
    ReviewType,
} from 'src/models/dto/approval/ReviewDTO'
import { AddCommentForm, Comment } from 'src/pages/module-review/Comments'
import { Authenticator } from 'src/services/Authenticator'

export interface ModuleReviewProps {
    moduleVersionId: string
    review: ReviewDTO
    revisionNumber: string
    stageReviewers?: string[]
}

export const getApprovalRequired = (rType: ReviewType) => {
    switch (rType) {
        case ReviewType.MAJOR:
            return 2
        case ReviewType.MINOR:
            return 1
        case ReviewType.PATCH:
            return 0
        default:
            return 2
    }
}

export function ModuleReviewComments({
    comments,
    isCommentsLoading = false,
}: {
    comments: CommentDTO[]
    isCommentsLoading?: boolean
}) {
    const commentsSorted = useMemo(() => _.orderBy(comments || [], 'createdAt', 'desc'), [comments])
    return (
        <Col padding={{ top: 'S200' }} gridGap='S300'>
            {isCommentsLoading && <Spinner dataTestId='comments-loading-spinner' />}
            {commentsSorted.length === 0 ? (
                <View>No comments.</View>
            ) : (
                commentsSorted.map((c, i, { length }) => (
                    <Comment key={i} comment={c} label={`Comment ${i + 1} of ${length}`} />
                ))
            )}
        </Col>
    )
}

export function ModuleReviewBox(props: ModuleReviewProps) {
    const { isCommentsLoading, comments } = useContext(ModuleReviewContext)
    const { review, revisionNumber, stageReviewers } = props
    const { reviewId, title, requester, revisionList, reviewers } = review
    const revision = useMemo(() => {
        if (!revisionList) {
            return null
        }

        return (
            revisionList.find((r) => r.revisionNumber.toString() === revisionNumber) ??
            _.last(revisionList)
        )
    }, [revisionList, revisionNumber])

    const isFastTrackProcess = revision && revision.stages

    const commentRef = useRef<HTMLDivElement | null>(null)

    const onCommentPosted = useCallback(() => {
        commentRef?.current?.focus()
    }, [commentRef])

    const stageRev = stageReviewers ? stageReviewers : []
    const consolidatedReviewers = isFastTrackProcess ? stageRev : reviewers

    return (
        <Col gridGap='S400' dataTestId='module-review-box'>
            <View>
                <H3 fontSize='T500'>Review</H3>
                <Hr />
                <Spacer height='S300' />
                <Col gridGap='S100'>
                    <View>Title: {title}</View>
                    <View>
                        Requester: {isFastTrackProcess && revision ? revision.createdBy : requester}
                    </View>
                    {consolidatedReviewers.length > 0 && (
                        <View>Reviewers: {consolidatedReviewers.join(', ')}</View>
                    )}
                    <View>Revision: {revision?.revisionNumber ?? ''}</View>
                    <View>
                        <Markdown markdown={'Description: ' + (revision?.description ?? '')} />
                    </View>
                </Col>
            </View>
            <View>
                <Label htmlFor='module-comment-textarea' id='comments-label'>
                    <H4 fontSize='T400' aria-describedby='comments-heading-sro-only'>
                        Comments
                    </H4>
                    <ScreenReaderOnly id='comments-heading-sro-only'>
                        {`There are ${(comments ?? []).length} comment(s)`}
                    </ScreenReaderOnly>
                </Label>
                <Hr />
                <Spacer height='S200' />
                <View>
                    <AddCommentForm
                        commentTextAreaId={'module-comment-textarea'}
                        commentTextAreaLabelledBy={'comments-label'}
                        onCommentPosted={onCommentPosted}
                        {...{ reviewId, revisionNumber }}
                    />
                </View>
                <Spacer height='S300' />
                <View
                    aria-label={`${
                        (comments ?? []).length
                    } comments, sorted by latest comment first`}
                    tabIndex={-1}
                    ref={commentRef}
                    style={{ maxHeight: 600, overflow: 'auto' }}
                >
                    <ModuleReviewComments
                        comments={comments || []}
                        isCommentsLoading={isCommentsLoading}
                    />
                </View>
            </View>
        </Col>
    )
}

interface ModuleReviewFormProps {
    versionId: string
    onCreateReviewInputChange?: (review: CreateModuleReviewInput) => void
    onCreateGroupReviewInputChange?: (review: ModuleGroupReviewRequest) => void
    reviewIds?: string[]
    moduleName: string
    descriptionError: boolean
    reviewType: ReviewType
    recommendedReviewType: ReviewType
    reviewRequired: boolean
    changeListLoading: boolean
    moduleChangeTextList: string[]
    diffApiError: boolean
    setReviewType: (reviewType: ReviewType) => void
    setDescriptionError: (descriptionError: boolean) => void
}

export const COMMENT_RECOMMENDED_NUMBER_OF_WORDS = 150

export function ModuleReviewForm(props: ModuleReviewFormProps) {
    const {
        versionId,
        reviewIds,
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        moduleName,
        descriptionError,
        reviewType,
        recommendedReviewType,
        reviewRequired,
        changeListLoading,
        moduleChangeTextList,
        diffApiError,
        setReviewType,
        setDescriptionError,
    } = props
    const reviewId = useMemo(() => (reviewIds?.length ? reviewIds[0] : ''), [reviewIds])
    const requester = Authenticator.getDefaultUser()
    const [description, setDescription] = useState('')
    const [reviewers, setReviewers] = useState([])
    const [showRest, setShowRest] = useState(false)

    const getApproverMemberList = (reviewersList: string[]): ReviewerMember[] => {
        const approvers = getReviewersList(Authenticator.getDefaultUserName())

        return approvers.map((app) => ({
            alias: app,
            assigned: reviewersList.includes(app),
        }))
    }

    const { footerText, warning, progress } = useRecommendedLength({
        value: description,
        tokenType: TokenType.Words,
        recommendedLength: COMMENT_RECOMMENDED_NUMBER_OF_WORDS,
    })

    const minimumRequiredText = ' (minimum required)'

    const evaluatedReviewTypeText = (rType: ReviewType) => {
        switch (rType) {
            case ReviewType.MAJOR:
                return 'Full review'
            case ReviewType.MINOR:
                return 'One approval'
            case ReviewType.PATCH:
                return 'No review'
            default:
                return 'Full review'
        }
    }

    const shouldDisableOption = (rType: ReviewType) =>
        getApprovalRequired(rType) < getApprovalRequired(recommendedReviewType)

    useEffect(() => {
        onCreateReviewInputChange?.({
            moduleVersionId: versionId,
            reviewId: reviewId || undefined,
            title: moduleName,
            description,
            requester,
            reviewers: reviewers,
            reviewUrl: window.location.origin.concat(`/module/viewer/${versionId}/review`),
            reviewType: ReviewType[reviewType],
            reviewerMemberList: getApproverMemberList(reviewers),
        })
    }, [
        onCreateReviewInputChange,
        onCreateGroupReviewInputChange,
        description,
        reviewers,
        requester,
        reviewId,
        moduleName,
        versionId,
        reviewType,
    ])

    const firstThreeItems = moduleChangeTextList.slice(0, 3)
    const restOfItems = moduleChangeTextList.slice(3, moduleChangeTextList.length)
    const remainingItemsNumber = moduleChangeTextList.length - 3

    const evalReviewTypeText = evaluatedReviewTypeText(recommendedReviewType)
    const noReviewRequired = recommendedReviewType === ReviewType.PATCH

    const noReviewText = ' is required based on the changes you made.'
    const reviewText = ' is the required next step based on the changes you made.'

    return (
        <Col gridGap='S300'>
            {diffApiError && (
                <MessageBanner type={MessageBannerType.Error}>
                    There was an unexpected error while determining change list. Please try again or
                    proceed with Full review as next step.
                </MessageBanner>
            )}
            {changeListLoading ? (
                <View>
                    <H3 fontSize='T200' fontWeight='bold'>
                        Retrieving changes made since last approved version...
                    </H3>
                    <Spinner dataTestId='change-list-loader-spinner' />
                </View>
            ) : (
                <Col gridGap={'S100'}>
                    <View>
                        <H2 fontSize='T200' fontWeight='bold'>
                            Changes made:
                        </H2>
                        <View>
                            {firstThreeItems.map((item, index) => (
                                <Text key={index} fontSize='T100'>{`\u2022 ${item}`}</Text>
                            ))}
                        </View>
                        {showRest && (
                            <View>
                                {restOfItems.map((item, index) => (
                                    <Text key={index + 2} fontSize='T100'>{`\u2022 ${item}`}</Text>
                                ))}
                            </View>
                        )}
                        {remainingItemsNumber > 0 && (
                            <Text
                                textDecoration='underline'
                                color='primary70'
                                onClick={() => setShowRest(!showRest)}
                                fontSize='T100'
                            >
                                {!showRest ? `+${remainingItemsNumber} more` : 'Less'}
                            </Text>
                        )}
                    </View>
                    <View>
                        <MessageBanner
                            dismissButtonAltText='Dismiss'
                            type={MessageBannerType.Informational}
                        >
                            {<strong>{evalReviewTypeText}</strong>}
                            {noReviewRequired ? noReviewText : reviewText}
                        </MessageBanner>
                    </View>
                </Col>
            )}
            <Col padding='S100'>
                <H2 fontSize='T200' fontWeight='bold'>
                    Next step:
                </H2>
                <fieldset>
                    <Spacer height='S300' />
                    <Col gridGap='S200'>
                        <Row alignItems='center' gridGap='S200'>
                            <InputWrapper
                                id='review-type-major'
                                labelText={
                                    'Review and 2 approvals' +
                                    (recommendedReviewType === ReviewType.MAJOR
                                        ? minimumRequiredText
                                        : '')
                                }
                                labelPosition={LabelPosition.Trailing}
                            >
                                {({ ...inputProps }) => (
                                    <Radio
                                        name='review-major'
                                        disabled={changeListLoading}
                                        checked={reviewType === ReviewType.MAJOR}
                                        onChange={() => setReviewType(ReviewType.MAJOR)}
                                        {...inputProps}
                                    />
                                )}
                            </InputWrapper>
                        </Row>
                        <Row alignItems='center' gridGap='S200'>
                            <InputWrapper
                                id='review-minor-radio'
                                labelText={
                                    'Review and 1 approval' +
                                    (recommendedReviewType === ReviewType.MINOR
                                        ? minimumRequiredText
                                        : '')
                                }
                                labelPosition={LabelPosition.Trailing}
                            >
                                {({ ...inputProps }) => (
                                    <Radio
                                        name='review-minor'
                                        disabled={
                                            changeListLoading ||
                                            shouldDisableOption(ReviewType.MINOR)
                                        }
                                        checked={reviewType === ReviewType.MINOR}
                                        onChange={() => setReviewType(ReviewType.MINOR)}
                                        {...inputProps}
                                    />
                                )}
                            </InputWrapper>
                        </Row>
                        <Row alignItems='center' gridGap='S200'>
                            <InputWrapper
                                id='review-patch-radio'
                                labelText={
                                    'No review or approval needed' +
                                    (recommendedReviewType === ReviewType.PATCH
                                        ? minimumRequiredText
                                        : '')
                                }
                                labelPosition={LabelPosition.Trailing}
                            >
                                {({ ...inputProps }) => (
                                    <Radio
                                        name='review-patch'
                                        disabled={
                                            changeListLoading ||
                                            shouldDisableOption(ReviewType.PATCH)
                                        }
                                        checked={reviewType === ReviewType.PATCH}
                                        onChange={() => {
                                            setReviewers([])
                                            setReviewType(ReviewType.PATCH)
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputWrapper>
                        </Row>
                    </Col>
                </fieldset>
            </Col>

            <InputWrapper
                id='request-reviewers-list'
                labelText='Reviewers'
                required={reviewRequired}
                error={reviewRequired && reviewers.length < getApprovalRequired(reviewType)}
                footer={
                    reviewRequired && reviewers.length < getApprovalRequired(reviewType)
                        ? `Must select at least ${getApprovalRequired(reviewType)} approver(s)`
                        : undefined
                }
            >
                {(inputProps) => (
                    <Select
                        {...inputProps}
                        data-test-id='request-reviewers-select'
                        options={getReviewersList(Authenticator.getDefaultUserName())}
                        defaultValue={[]}
                        disabled={!reviewRequired || changeListLoading}
                        isMulti={true}
                        onChange={setReviewers}
                        placeholder={reviewRequired ? 'Select reviewers' : 'No reviewers needed'}
                        value={reviewers}
                        getTriggerText={(numberOfSelectedItems: number) =>
                            numberOfSelectedItems === 1
                                ? '1 reviewer selected'
                                : `${numberOfSelectedItems} reviewers selected`
                        }
                        listMaxHeight='20vh'
                    />
                )}
            </InputWrapper>

            <View>
                <InputWrapper
                    id='request-review-description'
                    labelText='Comment'
                    error={descriptionError}
                    footer={(footerProps) => (
                        <RecommendedLengthFooter progress={progress} {...footerProps}>
                            {descriptionError
                                ? description.length < 1
                                    ? 'A comment must be provided'
                                    : 'Comment cannot exceed 1500 characters'
                                : footerText}
                        </RecommendedLengthFooter>
                    )}
                    warning={warning}
                    required
                >
                    {(inputProps) => (
                        <TextArea
                            minHeight='124px'
                            dataTestId='request-review-description'
                            disabled={changeListLoading}
                            value={description}
                            {...inputProps}
                            onChange={(e) => {
                                setDescription(e.target.value)
                                setDescriptionError(false)
                            }}
                        />
                    )}
                </InputWrapper>
            </View>
        </Col>
    )
}
