import React, { useCallback, useState } from 'react'
import PapaParse, { ParseResult } from 'papaparse'

import { Button, ButtonVariant } from '@amzn/stencil-react-components/button'
import { FileUpload } from '@amzn/stencil-react-components/file-upload'
import { IconUpload } from '@amzn/stencil-react-components/icons'
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner'
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal'

import { AdaptiveEngineItemParametersDTO } from 'src/models/dto/activities/AdaptiveEngineSelectionGroupDTO'
import { AdaptiveEngineHandler } from 'src/services/EntityServices/ActivityUpdateHandlers/AdaptiveEngineHandler'

export enum AdaptiveCSVHeader {
    'pageID' = 'Item ID.',
    'difficulty' = 'Difficulty',
    'discrimination' = 'Discrimination',
    'guessing' = 'Guessing',
    'group' = 'ItemEquivGroup',
}

export const CSV_HEADER = 'Item ID.,Difficulty,Discrimination,Guessing,ItemEquivGroup'

function quoteCSV(value: string | number): string {
    const str = `${value}`
    if (str.includes('"')) {
        return `"${str.replace(/"/g, '""')}"`
    }
    return str
}

export function rowsToCSV(rows: AdaptiveEngineItemParametersDTO[]): string {
    const rowsPart = rows
        .map((row) =>
            [row.pageID, row.difficulty, row.discrimination, row.guessing, row.group]
                .map(quoteCSV)
                .join(',')
        )
        .join('\n')

    return `${CSV_HEADER}\n${rowsPart}`
}

export async function loadFile(file: string | File) {
    const parsed = await new Promise<ParseResult<Record<AdaptiveCSVHeader, string>>>((complete) =>
        PapaParse.parse(file, {
            skipEmptyLines: true,
            delimiter: ',',
            header: true,
            complete,
        })
    )

    if (parsed.errors.length > 0) {
        throw new Error(
            'Failed to parse CSV:\n' +
                parsed.errors.map((e) => `Row ${e.row ?? 'unknown'}: ${e.message}`).join('\n')
        )
    }

    const missingCols: string[] = []
    for (const colName of Object.values(AdaptiveCSVHeader)) {
        if (!parsed.meta.fields?.includes(colName)) {
            missingCols.push(colName)
        }
    }
    if (missingCols.length > 0) {
        throw new Error(
            `Failed to parse CSV: Missing columns: ${missingCols.map((x) => `'${x}'`).join(', ')}`
        )
    }

    const errors: string[] = []
    const rows: AdaptiveEngineItemParametersDTO[] = []
    parsed.data.forEach((row, i) => {
        const getCol = (rowName: AdaptiveCSVHeader): string => row[rowName]?.trim() ?? ''
        const rowNo = i + 1
        const pageID = getCol(AdaptiveCSVHeader.pageID)
        if (!pageID) {
            errors.push(`Row ${rowNo} column '${AdaptiveCSVHeader.pageID}': cannot be empty.`)
            return
        }

        const difficulty = parseFloat(getCol(AdaptiveCSVHeader.difficulty))
        if (!Number.isFinite(difficulty)) {
            errors.push(`Row ${rowNo} column '${AdaptiveCSVHeader.difficulty}': must be a number.`)
            return
        }

        const discrimination = parseFloat(getCol(AdaptiveCSVHeader.discrimination))
        if (!Number.isFinite(discrimination)) {
            errors.push(
                `Row ${rowNo} column '${AdaptiveCSVHeader.discrimination}': must be a number.`
            )
            return
        }

        const guessing = parseFloat(getCol(AdaptiveCSVHeader.guessing))
        if (!Number.isFinite(guessing)) {
            errors.push(`Row ${rowNo} column '${AdaptiveCSVHeader.guessing}': must be a number.`)
            return
        }

        const group = parseInt(getCol(AdaptiveCSVHeader.group))
        if (!Number.isInteger(group) || group < 0) {
            errors.push(`Row ${rowNo} column '${AdaptiveCSVHeader.guessing}': must be an integer.`)
            return
        }

        rows.push({
            pageID,
            difficulty,
            discrimination,
            guessing,
            group,
        })
    })

    if (errors.length > 0) {
        throw new Error('Failed to parse CSV due to invalid data: \n' + errors.join('\n'))
    }
    return rows
}

/** Extract the UUID (V4) from the filename if present. */
export const getItemPoolIdFromFilename = (filename: string): string | undefined => {
    const match = filename.match(
        /([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i
    )
    return match ? match[1] : undefined
}

export const AdaptiveEngineCSVImporterModal = ({
    close,
    onSubmit,
}: {
    close: () => void
    onSubmit: (rows: AdaptiveEngineItemParametersDTO[], itemPoolId: string | undefined) => void
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('')
    const onFileAttached = useCallback(
        async (files: File[]) => {
            if (files.length === 0) {
                return
            }
            try {
                const loaded = await loadFile(files[0])
                onSubmit(loaded, getItemPoolIdFromFilename(files[0].name))
            } catch (e) {
                setErrorMessage((e as Error).message)
            }
        },
        [onSubmit]
    )

    return (
        <ModalContent
            titleText='Upload CSV File'
            buttons={[
                <Button
                    dataTestId='adaptive-engine-csv-importer-modal-close'
                    key='close'
                    onClick={close}
                    variant={ButtonVariant.Secondary}
                >
                    Close
                </Button>,
            ]}
        >
            {errorMessage && (
                <MessageBanner type={MessageBannerType.Error}>{errorMessage}</MessageBanner>
            )}
            <FileUpload
                dataTestId='adaptive-engine-csv-importer-modal-file-upload'
                accept='text/csv'
                id='adaptive-engine-csv-importer-modal-file-upload'
                isMulti={false}
                onFileAttached={onFileAttached}
                disabled={false}
            />
        </ModalContent>
    )
}

export function useRenderCSVImporter(workflowEntityId: string) {
    return useCallback(
        ({ close }: { close: () => void }) => (
            <AdaptiveEngineCSVImporterModal
                onSubmit={(data, itemPoolId: string | undefined) => {
                    AdaptiveEngineHandler.setParameters(workflowEntityId, data)
                    if (itemPoolId) {
                        // AdaptiveEngineHandler.create() has already added itemPoolDistribution[0]
                        AdaptiveEngineHandler.setItemPoolId(workflowEntityId, 0, itemPoolId)
                    }
                    close()
                }}
                close={close}
            />
        ),
        [workflowEntityId]
    )
}

export const AdaptiveEngineCSVImporter = ({
    workflowEntityId,
    editDisabled,
}: {
    workflowEntityId: string
    editDisabled?: boolean
}) => {
    return (
        <>
            <WithModal renderModal={useRenderCSVImporter(workflowEntityId)}>
                {({ open }) => (
                    <Button
                        dataTestId='adaptive-engine-csv-importer-upload-button'
                        onClick={open}
                        icon={<IconUpload aria-hidden />}
                        disabled={editDisabled}
                    >
                        Upload CSV
                    </Button>
                )}
            </WithModal>
        </>
    )
}
