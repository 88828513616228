// This configuration maps the change in module keyPath to a user-friendly message
export const moduleReviewConfiguration = new Map<string, string>([
    ['moduleTimerConfig', 'Module timer config was changed'],
    ['compositeScores', 'Module level composite scores were added, removed or updated'],
    ['..scores', 'Item level scores were added'],
    ['..score', 'Item level scores were added'],
    ['scores', 'Item level scores were updated'],
    ['score', 'Item level scores were updated'],
    ['moduleType', 'Module type was changed'],
    ['layout', 'Module layout was changed'],
    ['userType', 'Module user type was changed'],
    ['assessmentMetadata', 'Assessment Metadata was changed'],
    ['autoProgressionOn', 'Module feature auto progression was changed'],
    ['evaluationModule', 'Progress Bar toggle was changed'],
    ['instructionalContent', 'Customized fly-out instructions were changed'],
    ['en_US', 'Text in the item or module updated or changed (other than score)'],
    ['localeWiseMedia', 'Media added, updated or removed in an item'],
    ['muppItemBankId', 'Item bank was added or updated'],
    ['.scoreItemRemoved', 'Scoring item was removed from a page'],
    ['.scoreItemAdded', 'Scoring item was added to a page'],
    ['.addPages', 'A new page was added'],
    ['.addItems', 'A new item was added'],
    ['.removePages', 'A page was removed'],
    ['.removeItems', 'An item was removed'],
    ['.translationChange', 'Translation locales or texts were added or changed'],
    ['.pageSettings', 'Page setting was changed (e.g. Description, Randomize content etc.)'],
    ['.itemSettings', 'Item setting was changed (e.g. Optional, Preserve Order etc.)'],
    ['.pagesOrder', 'Order of pages was changed'],
    ['.itemsOrder', 'Order of items was changed'],
    ['.pageOrItemOrder', 'Order of items or pages changed'],
    [
        'LAUNCH_BUCKETS_AND_CUPS_SELECTION',
        'Buckets and Cups item pool is added, updated or removed',
    ],
    ['LAUNCH_RANDOM_SELECTION', 'Random selection group was added, updated or removed'],
    ['LAUNCH_CAT', 'Work styles exam was added, updated, or removed'],
    ['LAUNCH_ADAPTIVE_ENGINE_SELECTION', 'Adaptive engine was added, updated or removed'],
    [
        '.audioScoringModel',
        'Scoring ML model or Transcription ML model was changed in Audio Evaluation item',
    ],
])

export const audioScoringConfig = [
    '.scoringMLModelVersion',
    '.transcriptionMLModel',
    '.scoringMLModel',
    '.transcriptionMLModelVersion',
]

export const pageSettingConfig = ['.randomizationEnabled', '.description', '.branchingConditions']

export const commonSettingConfig = [
    '.en_US',
    '.scores',
    '.score',
    '.selection',
    '.mediaManagerMediaVersionIds',
    '.localeWiseMedia',
]

export const specialPageSettingConfig = [
    '.LAUNCH_ADAPTIVE_ENGINE_SELECTION',
    '.LAUNCH_BUCKETS_AND_CUPS_SELECTION',
    '.LAUNCH_RANDOM_SELECTION',
    '.LAUNCH_CAT',
]

export const itemSettingConfig = ['.optional', '.preserveOrder']
