// TODO refactor to call this function instead in multiple places of this codebase
export function downloadAsFile(data: string | Blob, filename: string) {
    const url = URL.createObjectURL(data instanceof Blob ? data : new Blob([data]))
    try {
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
    } finally {
        URL.revokeObjectURL(url)
    }
}
