import { ModuleChange } from 'src/models/dto/approval/ReviewDTO'
import { Locale } from 'src/models/dto/Locale'
import {
    audioScoringConfig,
    commonSettingConfig,
    itemSettingConfig,
    moduleReviewConfiguration,
    pageSettingConfig,
    specialPageSettingConfig,
} from 'src/pages/module-review/ModuleReviewConfiguration'

export const constructChangeMessageList = (changeMap: Map<string, number>) => {
    const messageList: string[] = []
    changeMap.forEach((value, key) => {
        messageList.push(`${key} (${value})`)
    })

    return messageList
}

const unknownChange = 'Unknown change detected'

const pageRegex = /^workflow\.[^\s.]+(?:[-\w\s]+)?$/
const itemRegex = /^workflow\.[a-zA-Z0-9_-]+\.moduleChild.items\.[a-zA-Z0-9_-]+$/
const itemRegexOrdering = /^workflow\.[a-zA-Z0-9_-]+\.moduleChild.items/
const mediaTextRegex = /(?:[a-zA-Z0-9_-]+\.)*mediaTemplateVersionIds\.(?:[a-zA-Z]{2}_[A-Z]{2})/
const localeList: string[] = Object.values(Locale).filter((locale) => locale !== 'en_US')

const incrementMapEntry = (map: Map<string, number>, key: string) => {
    const currentValue = map.get(key) ?? 0
    map.set(key, currentValue + 1)
}

const isOrderingChange = (moduleChange: ModuleChange, tempKey: string) => {
    const changeType = moduleChange.changeTypes[0]
    return changeType === 'Ordering' && (tempKey === 'workflow' || itemRegexOrdering.test(tempKey))
}

const getSettingChange = (keyPath: string) => {
    if (audioScoringConfig.some((audioScoring) => keyPath.endsWith(audioScoring)))
        return '.audioScoringModel'
    if (pageSettingConfig.some((pageSetting) => keyPath.endsWith(pageSetting)))
        return '.pageSettings'
    if (itemSettingConfig.some((itemSetting) => keyPath.endsWith(itemSetting)))
        return '.itemSettings'
    return null
}

const getItemOrPageChange = (
    tempKey: string,
    majorType: boolean,
    isSpecialPageChange: boolean,
    operation?: string
) => {
    if (pageRegex.test(tempKey)) {
        if (operation === 'ADD' && !isSpecialPageChange) return '.addPages'
        if (operation === 'REMOVE' && !isSpecialPageChange) return '.removePages'
    }
    if (itemRegex.test(tempKey)) {
        if (operation === 'ADD') return majorType ? '.scoreItemAdded' : '.addItems'
        if (operation === 'REMOVE') return majorType ? '.scoreItemRemoved' : '.removeItems'
    }
    return null
}

const getTranslationChange = (keyPath: string) => {
    if (
        localeList.some((translation) => keyPath.endsWith(translation)) ||
        keyPath === 'availableLocales'
    ) {
        return '.translationChange'
    }
    return null
}

export const constructChangeMap = (moduleChanges: ModuleChange[]) => {
    const changeMap: Map<string, number> = new Map()
    moduleChanges.forEach((moduleChange) => {
        let tempKey = moduleChange.keyPath
        const majorType = moduleChange.classification === 'Major'

        if (isOrderingChange(moduleChange, tempKey)) {
            tempKey = '.pageOrItemOrder'
        }

        const isUpdateOperation = moduleChange.operation && moduleChange.operation === 'UPDATE'
        const isPageOrItemChange = pageRegex.test(tempKey) || itemRegex.test(tempKey)

        const commonChanges = commonSettingConfig.some((common) =>
            moduleChange.keyPath.endsWith(common)
        )

        const specialPageChanges = specialPageSettingConfig.some((specialPage) =>
            moduleChange.keyPath.endsWith(specialPage)
        )

        if (commonChanges || specialPageChanges) {
            const tempArray = moduleChange.keyPath.split('.')
            tempKey = tempArray[tempArray.length - 1]
        }

        if (isUpdateOperation && isPageOrItemChange && !specialPageChanges) {
            return
        }

        const itemOrPageChange = getItemOrPageChange(
            moduleChange.keyPath,
            majorType,
            specialPageChanges,
            moduleChange.operation
        )
        if (itemOrPageChange) {
            tempKey = itemOrPageChange
        }

        const translationChange = getTranslationChange(moduleChange.keyPath)
        if (translationChange) {
            tempKey = translationChange
        }

        const settingChange = getSettingChange(moduleChange.keyPath)
        if (settingChange) {
            tempKey = settingChange
        }

        if (mediaTextRegex.test(moduleChange.keyPath)) {
            return
        }

        const reviewMessage = moduleReviewConfiguration.get(tempKey)
        incrementMapEntry(changeMap, reviewMessage ?? unknownChange)
    })

    return changeMap
}
